import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const getText = post => {
  let text = ""
  let i = 0
  if (post._rawBody.length) {
    while (text.length < 70 && i < post._rawBody[0].children.length) {
      text += post._rawBody[0].children[i].text
      i++
    }
  }
  if (text.length > 70) {
    text = `${text
      .substr(0, 70)
      .substr(0, Math.min(text.length, text.lastIndexOf(" ")))}...`
  }
  return text
}

const ContentCard = ({ post }) => {
  return (
    <CardContent>
      <Link to={"/blog/" + post.slug.current} key={post._key}>
        {post.mainImage != null ? (
          <StyledImage
            image={post.mainImage.asset.gatsbyImageData}
            alt={post.title}
          />
        ) : (
          <Placeholder />
        )}
        <CardText>
          <PublishedDate>
            {post.publishedAt && post.publishedAt.split(" ")[0]}
            <br />
            {post.publishedAt && post.publishedAt.split(" ")[1]}
          </PublishedDate>
          <PostTitle>{post.title}</PostTitle>
          <PostText>{getText(post)}</PostText>
        </CardText>
      </Link>
    </CardContent>
  )
}

export default ContentCard

const CardContent = styled.article`
  position: relative;
  width: 100%;
  max-height: 400px;
  border-radius: 4px;
  margin-bottom: 50px;
  background-color: ${({ theme }) => theme.bgLight};

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    max-width: 400px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 0.3);
    transition: all 0.1s ease;

    &:hover {
      box-shadow: 1px 4px 17px rgb(0 0 0 / 0.3);
      transform: translate(-1px, -1px);
    }

    &:active {
      box-shadow: 1px 1px 5px rgb(0 0 0 / 0.3);
      transform: translate(0, 0);
    }
  }
`

const StyledImage = styled(GatsbyImage)`
  height: 250px;
  width: 100%;
  border: solid 3px ${({ theme }) => theme.bgLight};
  border-radius: 6px 6px 0 0;
`

const Placeholder = styled.div`
  height: 250px;
  width: 100%;
`

const CardText = styled.article`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.primaryDark};
  padding: 1.5rem 1rem 1rem 1rem;
`

const PublishedDate = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: -40px;
  right: 0;
  height: 70px;
  width: 70px;
  border: solid 3px ${({ theme }) => theme.primaryLight};
  border-radius: 50%;
  text-align: center;
  line-height: 1.1rem;
  padding-bottom: 5px;
  background-color: ${({ theme }) => theme.accent};
  color: ${({ theme }) => theme.primaryLight};
`

const PostTitle = styled.h3``

const PostText = styled.p``
