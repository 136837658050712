import * as React from "react"
import styled from "styled-components"
import Wave from "../components/Wave"

const PageHeaderBlogList = ({ children }) => {
  return (
    <Header>
      {children}
      <WaveContainer>
        <Wave />
      </WaveContainer>
    </Header>
  )
}

export default PageHeaderBlogList

const Header = styled.div`
  position: relative;
  text-align: center;
  padding: 7rem 0 2.5rem 0;

  /* Tablet and desktop */
  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    padding-top: 6rem;
    height: 12rem;
  }
`

const WaveContainer = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    display: block;
  }
`
