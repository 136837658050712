import * as React from "react"
import styled from "styled-components"

const PageContentBlogList = ({ children }) => {
  return <MainContent>{children}</MainContent>
}

export default PageContentBlogList

const MainContent = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.primaryDark},
      ${({ theme }) => theme.bgLight}
    );
  }
`
