import * as React from "react"
import styled from "styled-components"

const CardContainer = ({ children }) => {
  return <StyledCardContainer>{children}</StyledCardContainer>
}

export default CardContainer

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 2rem;
  }
`
