import * as React from "react"
import { graphql } from "gatsby"
import LayoutBlog from "../components/LayoutBlog"
import Container from "../components/Container"
import CardContainer from "../components/CardContainer"
import PageHeaderBlogList from "../components/PageHeaderBlogList"
import PageTitle from "../components/PageTitle"
import PageContentBlogList from "../components/PageContentBlogList"
import ContentCard from "../components/ContentCard"

const BlogPage = ({ data }) => {
  return (
    <LayoutBlog title="Blog">
      <PageHeaderBlogList>
        <PageTitle title="Blog" />
      </PageHeaderBlogList>
      <PageContentBlogList>
        <Container>
          <CardContainer>
            {data.allSanityPost.nodes.map(post => (
              <ContentCard key={post.title} post={post} />
            ))}
          </CardContainer>
        </Container>
      </PageContentBlogList>
    </LayoutBlog>
  )
}

export default BlogPage

export const query = graphql`
  query BlogPosts {
    allSanityPost(
      filter: { publishedAt: { ne: null } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt(formatString: "D MMM")
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
        _rawBody(resolveReferences: { maxDepth: 1 })
        _key
      }
    }
  }
`
